<!-- =========================================================================================
    File Name: Email.vue
    Description: Email Application (Inbox)
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div>
    <div id="email-app" class="border border-solid d-theme-border-grey-light rounded relative overflow-hidden">

        <vs-sidebar class="items-no-padding" parent="#email-app" :click-not-close="clickNotClose" :hidden-background="clickNotClose" v-model="isEmailSidebarActive">
            <email-sidebar  @emailSent="openMailId = null" :emailTags="emailTags" @closeSidebar="toggleEmailSidebar" :mailFilter="mailFilter"></email-sidebar>
        </vs-sidebar>

        <div :class="{'sidebar-spacer': clickNotClose}" class="app-fixed-height border border-solid d-theme-border-grey-light border-r-0 border-t-0 border-b-0">

            <!-- SEARCH BAR -->
            <div class="flex border items-center app-search-container">
                <feather-icon class="md:inline-flex lg:hidden ml-4 mr-4 cursor-pointer" icon="MenuIcon" @click.stop="toggleEmailSidebar(true)"></feather-icon>
                <vs-input icon="icon-search" size="large" icon-pack="feather" placeholder="Search Mail" v-model="searchQuery" class="vs-input-no-border vs-input-no-shdow-focus w-full no-icon-border" />
            </div>

            <!-- EMAIL ACTION BAR -->
            <div class="email__actions flex flex-wrap justify-between p-4 border border-r-0 border-l-0 border-solid d-theme-border-grey-light">
                <div>
                    <vs-checkbox v-model="selectAllCheckBox" icon-pack="feather" :icon="selectAllIcon" class="select-all-chexkbox ml-0">Select All</vs-checkbox>
                </div>
                <div class="flex">

                    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer" v-if="mailFilter != 'sent'">

                        <feather-icon icon="FolderIcon" class="cursor-pointer" svg-classes="h-6 w-6"></feather-icon>

                        <vs-dropdown-menu>
                            <ul class="my-2">
                                <li class="px-4 mb-2 flex items-start cursor-pointer hover:text-primary" @click="moveTo('inboxed')" v-if="mailFilter != 'inbox'">
                                    <feather-icon icon="MailIcon" svg-classes="h-5 w-5" />
                                    <span class="ml-3">Inbox</span>
                                </li>
                                <li class="px-4 mb-2 flex items-start cursor-pointer hover:text-primary" @click="moveTo('spam')" v-if="mailFilter != 'spam'">
                                    <feather-icon icon="InfoIcon" svg-classes="h-5 w-5"></feather-icon>
                                    <span class="ml-3">Spam</span>
                                </li>
                                <li class="px-4 flex items-start cursor-pointer hover:text-primary" @click="moveTo('trashed')" v-if="mailFilter != 'trash'">
                                    <feather-icon icon="TrashIcon" svg-classes="h-5 w-5"></feather-icon>
                                    <span class="ml-3">Trash</span>
                                </li>
                            </ul>
                        </vs-dropdown-menu>
                    </vs-dropdown>

                    <feather-icon icon="MailIcon" class="ml-5 cursor-pointer" svg-classes="h-6 w-6" @click="updateMarkUnread"></feather-icon>

                    <feather-icon icon="TrashIcon" class="cursor-pointer ml-5" svg-classes="h-6 w-6" v-if="mailFilter != 'trash'" @click="moveTo('trashed')"></feather-icon>
                  <feather-icon icon="TrashIcon" color="danger" class="cursor-pointer ml-5" svg-classes="h-6 w-6 text-danger" @click="openConfirm" v-else></feather-icon>
                </div>
            </div>

            <!-- EMAILS LIST -->
            <VuePerfectScrollbar class="email-content-scroll-area" :settings="settings" ref="mailListPS">
                <transition-group name="list-enter-up" class="email__mails" tag="ul" appear>
                    <li class="cursor-pointer email__mail-item" v-for="(mail, index) in mails" :key="String(mailFilter) + String(mail.id)" @click.stop="updateOpenMail(mail.id)" :style="{transitionDelay: (index * 0.1) + 's'}">
                        <mail-item :mail="mail" :isMailOpen="isMailOpen(mail.id)" :isSelected="isMailSelected(mail.id)" @addToSelected="addToSelectedMails" @removeSelected="removeSelectedMail"></mail-item>
                    </li>
                </transition-group>
            </VuePerfectScrollbar>
        </div>

        <!-- EMAIL VIEW SIDEBAR -->
        <email-view
            :emailTags = "emailTags"
            :openMailId = "openMailId"
            :isSidebarActive = "isSidebarActive"
            :mailFilter = "mailFilter"
            @markUnread = "updateSingleMarkUnread"
            @removeMail = "removeOpenMail"
            @previousMail = "previousMail"
            @nextMail = "nextMail"
            @moveTo = "moveCurrentTo"
            @deleteCurrent = "deleteCurrent"
            @closeSidebar = "closeMailViewSidebar">
        </email-view>
    </div>
    <div v-if="openMailId" id="section-to-print" class="only_print">
      <div class="vx-row">
        <div class="vx-col w-full">
          <div>

            <!-- MAIL META ROW -->
            <div class="vx-row w-full border-b border-l-0 border-r-0 border-t-0 d-theme-border-grey-light border-solid flex justify-between flex items-center">
              <div class="vx-col sm:w-4/5 w-full flex flex-wrap items-center mb-2">
                <div class="flex flex-col">
                  <div class="p-2">
                    <p class=" text-sm mb-1">From:</p>
                    <p class="mb-1 ml-2">{{ currentMail.sender_name }}</p>
                    <p class="text-sm  ml-2 mb-1"><span class="font-semibold"> {{ currentMail.sender }} </span></p>
                    <p class="text-sm mb-1">To: <span class="font-semibold"> {{ currentMail.to }} </span></p>
                    <p class="text-sm mb-1">Date: <span class="font-semibold"> {{ currentMail.time | time }} {{ currentMail.time | date(true) }} </span></p>
                    <p class="text-sm mb-1" v-if="currentMail.cc.length">cc: <span class="font-semibold"> {{ currentMail.cc }} </span></p>
                  </div>
                </div>
              </div>
            </div>
            <div class="vx-row w-full border-b border-l-0 border-r-0 border-t-0 d-theme-border-grey-light border-solid flex justify-between flex items-center">
              <div class="vx-col sm:w-4/5 w-full flex flex-wrap items-center">
                <div class="flex flex-col">
                  <h5 class="mb-1 mt-1"><span>{{ currentMail.subject }}</span></h5>
                </div>
              </div>
            </div>

            <!-- MAIL CONTENT -->
            <div class="vx-row">
              <div class="vx-col w-full">
                <div class="mail__content break-words mt-8 mb-4" v-html="currentMail.message"></div>
              </div>
            </div>

            <!-- MAIL ATTACHMENTS -->
            <div class="vx-row" v-if="currentMail.attachments.length">
              <div class="vx-col w-full border-b border-l-0 border-r-0 border-t-0 d-theme-border-grey-light border-solid mb-6 flex">
                <feather-icon icon="PaperclipIcon" class="mr-2" />
                <span class="block py-4">ATTACHMENTS</span>
              </div>
              <div class="flex">
                <div class="mail__attachment" v-for="(attachment, index) in currentMail.attachments" :key="index">
                  <vs-chip color="primary"  style="cursor: pointer"  class="px-4 py-2 mr-3"><p @click="downloadFile(attachment.storage_name, attachment.attachment_name)">{{ attachment.attachment_name }}</p></vs-chip>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EmailSidebar from './EmailSidebar.vue'
import MailItem from './MailItem.vue'
import EmailView from './EmailView.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default{
    data() {
        return {
            openMailId: null,
            selectedMails: [],
            isSidebarActive: false,
            showThread: false,
            clickNotClose: true,
            isEmailSidebarActive: true,
            windowWidth: window.innerWidth,
            settings: {
                maxScrollbarLength: 60,
                wheelSpeed: 0.30,
            },
        }
    },
    watch: {
        isSidebarActive(value) {
            if(!value) this.showThread = false
        },
        mailFilter() {
            this.selectedMails = [];
            this.$refs.mailListPS.$el.scrollTop = 0;
        }
    },
    computed: {
        mailFilter() {
            return this.$store.state.email.mail_filter;
        },
        emailTags() {
            return this.$store.state.email.mailTags;
        },
        searchQuery: {
            get() {
                return this.$store.state.email.mailSearchQuery;
            },
            set(val) {
                this.$store.dispatch('email/setMailSearchQuery', val);
            }
        },
        selectAllCheckBox: {
            get() {
                return this.selectedMails.length;
            },
            set(value) {
                if(value) this.selectedMails = this.mails.map(mail => mail.id)
                else this.selectedMails = [];
            }
        },
        mails() {
            return this.$store.getters['email/filteredMails'];
        },
        isMailOpen() {
            return (mailId) => mailId == this.openMailId;
        },
        selectAllIcon() {
            if(this.selectedMails.length == this.mails.length) return 'icon-check'
            else return 'icon-minus'
        },
        isMailSelected() {
            return (mailId) => this.selectedMails.indexOf(mailId) == -1 ? false : true;
        },
        currentMail() {
          return this.$store.getters['email/getMail'](this.openMailId)
        },
    },
    methods: {
        updateOpenMail(mailId) {
            this.openMailId = mailId;
            const payload = {mails: [mailId], unread: false};
            this.axios.get(`/mailRead/${mailId}`) // Make Email unread in database
            .then((data) => {
              this.$store.dispatch('email/updateMailUnread', payload);
            })
            this.isSidebarActive = true;
        },
        addToSelectedMails(mailId) {
            this.selectedMails.push(mailId)
        },
        removeSelectedMail(mailId) {
            const mailIndex = this.selectedMails.indexOf(mailId);
            if(mailIndex > -1) {
                this.selectedMails.splice(mailIndex, 1)
            }
        },
        moveTo(to) {
            const payload = {mails: this.selectedMails, to: to}
          if (to == 'trashed') {
            this.axios.post('mail/markTrash',payload)
              .then(({data}) => {
                if (data.status) {
                  this.$store.dispatch('email/moveMailsTo', payload)
                  this.selectedMails = [];
                  this.$vs.notify({
                    title:'Success',
                    text:'Mails moved to trash',
                    color:'danger'
                  })
                }
              })
          } else if(to == 'spam') {
            this.axios.post('mail/markSpam',payload)
              .then(({data}) => {
                if (data.status) {
                  this.$store.dispatch('email/moveMailsTo', payload)
                  this.selectedMails = [];
                  this.$vs.notify({
                    title:'Success',
                    text:'Mails was moved to spam',
                    color:'warning'
                  })
                }
              })
          } else if(to == 'inboxed') {
            this.axios.post('mail/markInbox',payload)
              .then(({data}) => {
                if (data.status) {
                  this.$store.dispatch('email/moveMailsTo', payload)
                  this.selectedMails = [];
                  this.$vs.notify({
                    title:'Success',
                    text:'Mail was moved to inbox',
                    color:'success'
                  })
                }
              })
          }
        },
      deleteCurrent() {
        this.selectedMails = [this.openMailId];
        this.deleteMail();
      },
      openConfirm() {
        this.$vs.dialog({
          type: 'confirm',
          color: 'danger',
          title: `Confirm`,
          text: 'Are you sure to delete emails permanently',
          accept: this.deleteMail
        })
      },
      deleteMail(){
        const payload = {mails: this.selectedMails}

        this.axios.post('mail/delete',payload)
          .then(({data}) => {
            this.selectedMails = [];
            this.axios.get('mails')
              .then(({data}) => {
                this.$store.commit('email/UPDATE_MAILS', data);
                this.$vs.notify({
                  title:'Success',
                  text:'Mails was Deleted!',
                  color:'green'
                })
              });
          })
      },
        moveCurrentTo(to) {
            this.selectedMails = [this.openMailId];
            this.moveTo(to)
        },
        updateMarkUnread() {
            const payload = {mails: this.selectedMails, unread: true}
          this.axios.post('mail/markUnread',payload)
            .then(({data}) => {
              console.log(data)
              if (data.status == 200) {
                this.$store.dispatch('email/updateMailUnread', payload)
                this.selectedMails = [];
                this.$vs.notify({
                  title:'Success',
                  text:'Mails was marked unread!',
                  color:'success'
                })
              }
            })
        },
        removeOpenMail() {
            this.selectedMails = [this.openMailId];
            this.moveTo('trashed');
            this.isSidebarActive = false;
        },
        toggleIsStarred() {
            const payload = {mailId: this.currentMail.id, value : !this.currentMail.isStarred}
            this.$store.dispatch('email/toggleIsMailStarred', payload)
        },
        nextMail() {
            const currentMailIndex = this.mails.findIndex((mail) => mail.id == this.openMailId)
            if(this.mails[currentMailIndex + 1]) this.openMailId = this.mails[currentMailIndex + 1].id
        },
        previousMail() {
            const currentMailIndex = this.mails.findIndex((mail) => mail.id == this.openMailId)
            if(this.mails[currentMailIndex - 1]) this.openMailId = this.mails[currentMailIndex - 1].id
        },
        updateSingleMarkUnread() {
            this.selectedMails = [this.openMailId];
            this.updateMarkUnread();
            this.isSidebarActive = false;
        },
        addLabelToMails(label) {
            const payload = {mails: this.selectedMails, label: label}
            this.$store.dispatch('email/addLabelToMails', payload);
            this.selectedMails = [];
        },
        closeMailViewSidebar() {
            this.isSidebarActive = false;
        },
        handleWindowResize(event) {
            this.windowWidth = event.currentTarget.innerWidth;
            this.setSidebarWidth();
        },
        setSidebarWidth() {
            if(this.windowWidth < 992) {
                this.isEmailSidebarActive = this.clickNotClose = false;
            }else {
                this.isEmailSidebarActive = this.clickNotClose = true;
            }
        },
        toggleEmailSidebar(value = false) {
            if(!value) {
                this.closeMailViewSidebar()
                if(this.clickNotClose) {
                    return
                }
            }
            this.isEmailSidebarActive = value;
        },
      update(){
        this.axios.get('mails')
          .then(({data}) => {
            this.$store.commit('email/UPDATE_MAILS', data);
          });
      }
    },
    components: {
        MailItem,
        EmailSidebar,
        EmailView,
        VuePerfectScrollbar
    },
    mounted(){
      this.update();
      Echo.connector.pusher.config.auth.headers['Authorization'] = 'Bearer '+this.$auth.token();
      Echo.private('App.User.' + this.$auth.user().id)
        .notification((notification) => {
          this.update();
        });
    },
    created() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.handleWindowResize);
        })
        this.setSidebarWidth();
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.handleWindowResize)
    },
}
</script>

<style lang="scss">
@import "@/assets/scss/vuesax/apps/email.scss";
</style>
