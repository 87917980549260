<!-- =========================================================================================
    File Name: EmailSidebar.vue
    Description: Email Application Sidebar(Filter) (Inbox)
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <div class="email__email-sidebar h-full">
        <div class="m-6 clearfix">
            <vs-button size="large" class="bg-primary-gradient w-full" icon-pack="feather" icon="icon-edit" @click="show_form">Compose</vs-button>
        </div>

        <!-- compose email -->
      <vs-popup
            class="email-compose"
            title="New Message"
            accept-text= "Send"
            @cancel="confirmDiscard"
            @accept="sendMail"
            @close="confirmDiscard"
            :is-valid="validateForm"
            :active.sync="activePrompt">
                <VuePerfectScrollbar class="scroll-area p-4" :settings="settings">
                    <vs-input v-validate="'required|email'" name="To" label-placeholder="To" v-model="mailTo" class="w-full mb-6" :danger="!validateForm && mailTo != ''" val-icon-danger="clear" :success="validateForm" val-icon-success="done" :color="validateForm ? 'success' : 'danger'" />
                      <span class="text-danger text-sm"  v-show="errors.has('To')">{{ errors.first('To') }}</span>
                      <vs-input name="mailSubject" label-placeholder="Subject" v-model="mailSubject" class="w-full mb-6" />
                        <!--<vs-input name="CC" v-validate="'email'" label-placeholder="CC" v-model="mailCC" class="w-full mb-6" />-->
                      <v-select multiple :closeOnSelect="false" v-model="mailCC" taggable push-tags :options="[]"  class="w-full mb-6" />
                      <span class="text-danger text-sm"  v-show="errors.has('CC')">{{ errors.first('CC') }}</span>
                      <v-select multiple :closeOnSelect="false" v-model="mailBCC" taggable push-tags :options="[]"  class="w-full mb-6" />
                      <span class="text-danger text-sm"  v-show="errors.has('BCC')">{{ errors.first('BCC') }}</span>
                      <quill-editor name = 'Message' v-validate="'required'" v-model="mailMessage" :options="editorOption"></quill-editor>
                      <span class="text-danger text-sm"  v-show="errors.has('Message')">{{ errors.first('Message') }}</span>
                      <div class="vx-col w-full border-b border-l-0 border-r-0 border-t-0 d-theme-border-grey-light border-solid mb-6 flex">
                        <feather-icon icon="PaperclipIcon" class="mr-2" />
                        <span class="block py-4">ATTACHMENTS</span>
                      </div>
                      <div class="flex" v-if="attached">
                        <div class="mail__attachment" :key="index" v-for="(attachment, index) in attached">
                          <vs-chip color="primary" @click="removeAttachment(index)"  class="px-4 py-2" closable><p>{{ attachment.attachment_name }}</p></vs-chip>
                        </div>
                      </div>
                      <vs-upload :key="uploadKey" @on-success="uploadSuccess" @on-error="uploadFailed" fileName="attachment[]" :action="baseUrl+'/api/upload'" :automatic="true" :headers="{Authorization:'Bearer '+$auth.token()}"    multiple text="Attachments" :show-upload-button="true" />
                      <vs-button  size="medium" class="bg-primary-gradient w-full" icon-pack="feather" @click.prevent="sendMail" icon="icon-send">{{ replyMode ? 'Send Reply': 'Send' }}</vs-button><br>
                      <h5 class="text-center">Or</h5><br>
                  <vs-button size="medium" class="bg-warning-gradient w-full" icon-pack="feather" icon="icon-edit" @click="save_draft">Save as draft</vs-button>
                </VuePerfectScrollbar>
      </vs-popup>

      <VuePerfectScrollbar class="email-scroll-area" :settings="settings">
            <div class="px-6 pb-2 flex flex-col">

                <!-- inbox -->
                <div class="flex justify-between items-center cursor-pointer" :class="{'text-primary': mailFilter == 'inbox'}" @click="updateFilter('inbox')">
                    <div class="flex items-center mb-2">
                        <feather-icon icon="MailIcon" :svgClasses="[{'text-primary stroke-current': mailFilter == 'inbox'}, 'h-6 w-6']"></feather-icon>
                        <span class="text-lg ml-3">Inbox</span>
                    </div>
                    <vs-chip class="number" color="primary" v-if="unreadMails('inboxed') > 0">{{ unreadMails('inboxed') }}</vs-chip>
                </div>

                <!-- sent -->
                <div class="flex items-center mt-4 mb-2 cursor-pointer" :class="{'text-primary': mailFilter == 'sent'}" @click="updateFilter('sent')">
                    <feather-icon icon="SendIcon" :svgClasses="[{'text-primary stroke-current': mailFilter == 'sent'}, 'h-6 w-6']"></feather-icon>
                    <span class="text-lg ml-3">Sent</span>
                </div>

                <!-- draft -->
                <div class="flex justify-between items-center mt-4 cursor-pointer" :class="{'text-primary': mailFilter == 'draft'}" @click="updateFilter('draft')">
                    <div class="flex items-center mb-2">
                        <feather-icon icon="Edit2Icon" :svgClasses="[{'text-primary stroke-current': mailFilter == 'draft'}, 'h-6 w-6']"></feather-icon>
                        <span class="text-lg ml-3">Draft</span>
                    </div>
                    <vs-chip class="number" color="warning" v-if="draftMails > 0">{{ draftMails }}</vs-chip>
                </div>

                <!-- starred -->
                <div class="flex items-center mt-4 mb-2 cursor-pointer" :class="{'text-primary': mailFilter == 'starred'}" @click="updateFilter('starred')">
                    <feather-icon icon="StarIcon" :svgClasses="[{'text-primary stroke-current': mailFilter == 'starred'}, 'h-6 w-6']"></feather-icon>
                    <span class="text-lg ml-3">Starred</span>
                </div>

                <!-- spam -->
                <div class="flex items-center justify-between items-center mt-4 cursor-pointer" :class="{'text-primary': mailFilter == 'spam'}" @click="updateFilter('spam')">
                    <div class="flex items-center mb-2">
                        <feather-icon icon="InfoIcon" :svgClasses="[{'text-primary stroke-current': mailFilter == 'spam'}, 'h-6 w-6']"></feather-icon>
                        <span class="text-lg ml-3">Spam</span>
                    </div>
                    <vs-chip class="number" color="danger" v-if="unreadMails('spam') > 0">{{ unreadMails('spam') }}</vs-chip>
                </div>

                <!-- trash -->
                <div class="flex items-center mt-4 mb-2 cursor-pointer" :class="{'text-primary': mailFilter == 'trash'}" @click="updateFilter('trash')">
                    <feather-icon icon="TrashIcon" :svgClasses="[{'text-primary stroke-current': mailFilter == 'trash'}, 'h-6 w-6']"></feather-icon>
                    <span class="text-lg ml-3">Trash</span>
                </div>
            </div>
        </VuePerfectScrollbar>
    </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import { quillEditor } from 'vue-quill-editor'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import vSelect from 'vue-select'

export default{
    props: {
        emailTags: {
            type: Array,
            required: true,
        },
        mailFilter: {
            type: String,
            required: true,
        }
    },
    data() {
        return {
            uploadKey:0,
            activePrompt: false,
            replyMode:false,
            mailId:'',
            action:'',
            attachments:[],
            attached:[],
            mailTo: '',
            mailSubject: '',
            mailCC: '',
            mailBCC: '',
            mailMessage: '',
            editorOption: {
                modules: {
                    toolbar: [['bold', 'italic', 'underline', 'strike', 'link', 'blockquote', 'code-block'], [{ 'header': 1 }, { 'header': 2 }], [{ 'list': 'ordered'}, { 'list': 'bullet' }], [{ 'font': [] }],],
                },
                placeholder: 'Message'
            },
            settings: {
                maxScrollbarLength: 60,
                wheelSpeed: 0.30,
            },
          baseUrl:process.env.VUE_APP_BASE_URL
        }
    },
    computed: {
        validateForm() {
            return !this.errors.any() && this.mailTo != '';
        },
        unreadMails() {
            return (mailType) => this.$store.getters['email/unreadMails'](mailType);
        },
        draftMails() {
            return this.$store.getters['email/NumdraftMails'];
        },
    },
    methods: {
      uploadSuccess(event){
        this.attached = this.attached.concat(JSON.parse(event.currentTarget.response));
        this.uploadKey+=1;
      },
      uploadFailed(event){
        console.log(event);
      },
      catchAttachment(e,values){
        this.attachments.push(values);
      },
      confirmDiscard(){
        this.$vs.dialog({
          type: 'confirm',
          color: 'primary',
          title: `Confirm`,
          text: 'Do you want to save it for later as draft',
          accept: this.save_draft,
          cancel: this.clearFields
        })
      },
      save_draft(){
        this.$validator.validateAll().then(result => {
            if(result) {
              this.activePrompt= false;
              let data = {
                action:this.action,
                mailTo: this.mailTo,
                mailSubject:this.mailSubject,
                mailCc:this.mailCC,
                mailBCC:this.mailBCC,
                mailMessage:this.mailMessage,
                mailId:this.mailId,
                replyTo:this.replyTo,
                attachment:this.attachments,
                attached:this.attached
              }

              this.axios.post('/draftMail', data)

                .then(({data}) => {

                  if(data.status == 200){
                    this.clearFields();
                    this.attachments =[];

                    this.activePrompt= false;
                    this.$vs.notify({

                      title:'Success',

                      text:'Mail was saved as draft!',

                      color:'green'

                    })

                    this.axios.get('mails')

                      .then(({data}) => {

                        this.$store.commit('email/UPDATE_MAILS', data);


                      });

                  }else{
                    this.activePrompt= true;
                  }

                })
            }else{
              this.activePrompt= true;
              this.$vs.notify({
                title:'Incomplete data',
                text:'Please fill all required field. Message can not be saved as draft.',
                color:'red'
              })
            }
        })

      },
      show_form(){
        this.activePrompt = true;
      },
        updateFilter(filterName) {
            this.$store.dispatch('email/updateMailFilter', filterName);
            this.$emit('closeSidebar', false);
        },
        removeAttachment(i){
          this.axios.delete('/delete/'+this.attached[i].storage_name).then(()=>{
            this.attached.splice(i,1);
          });
        },
        clearFields() {
            this.action ='',
            this.mailTo = '';
            this.mailSubject = '';
            this.mailCC = '';
            this.mailBCC = '';
            this.mailMessage = '';
            this.attached = [];
            this.attachments =[];
            this.uploadKey+=1;
          this.$validator.reset();
        },
        sendMail() {
          this.$validator.validateAll().then(result => {
              if(result){
                this.activePrompt= false;
                this.axios.post('/sendMail', {
                  action:this.action,
                  mailTo: this.mailTo,
                  mailSubject:this.mailSubject,
                  mailCc:this.mailCC,
                  mailBCC:this.mailBCC,
                  mailMessage:this.mailMessage,
                  mailId:this.mailId,
                  replyTo:this.replyTo,
                  attachment:this.attachments,
                  attached:this.attached
                })
                  .then(({data}) => {
                    if(data.status == 200){
                      this.clearFields();
                      this.$vs.notify({
                        title:'Success',
                        text:'Mail was sent successfully!',
                        color:'green'
                      });
                      this.updateFilter('sent')
                      this.$emit('emailSent');
                      this.axios.get('mails')
                        .then(({data}) => {
                          this.$store.commit('email/UPDATE_MAILS', data);
                        });
                    }else{
                      this.activePrompt= true;
                    }
                  })
              }else{
                this.$vs.notify({
                  title:'Incomplete data',
                  text:'Please fill all required data.',
                  color:'red'
                })
              }
          });
      },
    },
    mounted(){
      this.$root.$on('populate_reply', data => {
        this.clearFields();
        this.replyMode=true;
        this.activePrompt = true;
        this.mailTo = data.sender;
        this.mailSubject = "RE: "+data.subject;
        this.mailCC = data.cc?data.cc.split(', '):[];
        this.mailBCC = data.bcc?data.bcc.split(', '):[];
        this.mailId = data.id;
        this.mailMessage = `
                  <br>
                  <br/>
                  <p>Previous messages</p>
                  ---------------------------------
                  <br>
                  <strong>From: ${data.sender_name} <br />
                  Sent: ${data.time.slice(0,-5)}  <br />
                  To: ${data.sender} <br />
                  Subject: RE: ${data.subject} <br />
                  <br />
                  ${data.message} <br /> </strong>
                  <br />
                   `;
      });

      this.$root.$on('populate_forward', data => {
        this.clearFields();
        this.mailSubject='Fwd: ' + data.subject;
        this.mailMessage = `
                  <br>
                  <br/>
                  <p>Forwarded messages</p>
                  ---------------------------------
                  <br>
                  <strong>From: ${data.sender_name} <br />
                  Sent: ${data.time.slice(0,-5)}  <br />
                  To: ${data.sender} <br />
                  Subject: RE: ${data.subject} <br />
                  <br />
                  ${data.message} <br /> </strong>
                  <br />
                   `;
        this.action = 'forward',
        this.attached = data.attachments;

        this.activePrompt = true;
      });
      this.$root.$on('populate_draft', data => {
        this.clearFields();
        this.action = 'sendDraft',
        this.attached = data.attachments;
        this.activePrompt = true;
        this.mailTo = data.to;
        this.mailSubject = data.subject;
        this.mailCC = data.cc?data.cc.split(', '):[];
        this.mailBCC = data.bcc?data.bcc.split(', '):[];
        this.mailId = data.id;
        this.mailMessage = data.message;
      });
    },
    components: {
        quillEditor,
        VuePerfectScrollbar,
        vSelect
    }
}
</script>
